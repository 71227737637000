import "@fontsource/inter";
import "./App.css";

import {
  ChakraProvider,
  Text,
  VStack,
} from "@chakra-ui/react";
import Conversation from "./components/Conversation";

import { isChrome, isMobile, isSafari } from "react-device-detect";
import { WarningIcon } from "@chakra-ui/icons";
import { SelfHostedConversationConfig } from "vocode";


const App = () => {
  const backendUrl = `wss://${process.env.REACT_APP_BACKEND_URL}/conversation` || "";
  const config: SelfHostedConversationConfig = {
    backendUrl,
    audioDeviceConfig: {},
    subscribeTranscript: false,
  };

  return (
    <ChakraProvider>
      {(!isChrome || isMobile) ? (
        <VStack padding={10} alignItems="center">
          <WarningIcon boxSize={100} />
          <Text paddingTop={4}>
            This demo works on Chrome (desktop) only!
          </Text>
        </VStack>
      ) : (
        <Conversation
          config={config}
        />
      )}
    </ChakraProvider>
  );
};

export default App;
